// import { SSO_API_URL, SSO_SITE_URL } from "@/constants";

// interface withFetchParams {
// 	url: RequestInfo
// 	headers?: RequestInit['headers']
// 	method: RequestInit['method']
// 	mode?: RequestInit['mode']
// 	credentials?: RequestInit['credentials']
// }

// interface fetchError {
//     Success: false;
//     Data: string[];
// }

// interface fetchSuccess<Type> {
//     Success: true;
//     Data: Type;
// }

// interface fetchThen<Type> {
// 	status: number
// 	headers: Headers
// 	response: fetchError | fetchSuccess<Type>
// }

// interface fetchCatch {
// 	error: unknown,
// 	response: fetchError
// }

// export const customFetch =
// 	<T = unknown>({ url, headers, method, mode, credentials }: withFetchParams) =>
// 	(dataParams?: string | Record<string, string>): Promise<fetchThen<T> | fetchCatch> => {
// 		const body: Record<string, string> = method == 'POST' ? { body: JSON.stringify(dataParams) } : {};

// 		url = method === 'GET' && typeof dataParams !== 'string' ? `${url}?${new URLSearchParams(dataParams).toString()}` : typeof dataParams === 'string' ? `${url}/${dataParams}` : url;

// 		return fetch(url, {
// 			headers: {
// 				'Content-Type': 'application/json',
// 				Accept: 'application/json',
// 				'Access-Control-Allow-Credentials': 'true',
// 				...headers,
// 			},
// 			credentials: credentials ?? 'include',
// 			mode: mode ?? 'cors',
// 			method,
// 			...body,
// 		})
// 			.then(async (response) => {

// 				if (response.status === 210) {
// 					localStorage.setItem('SSO_CREDENTIALS', JSON.stringify(`${response.headers.get('RefreshedToken')}`));
// 				}

// 				return {
// 					status: response.status,
// 					headers: response.headers,
// 					response: await response.json(),
// 				} as fetchThen<T>
// 			})
// 			.catch((error) => {
// 				return {
// 					error,
// 					response: {
// 						Success: false,
// 						Data: [String(error).split(': ')[1] ?? String(error)],
// 					}
// 				} as fetchCatch;
// 			});
// 	};

// export const validarOTP = customFetch<string>({ url: `${SSO_API_URL}/Valida/OTP`, method: 'POST' });
// export const validarSesion = customFetch({ url: `${SSO_API_URL}/Valida/Sesion`, method: 'POST' });

// // export const handleRedirectSSO = () => process.env.NODE_ENV != 'development' && (window.location.href = SSO_SITE_URL ?? '/');
// export const handleRedirectSSO = () => {
// 	localStorage.removeItem('SSO_CREDENTIALS');
// 	window.location.href = SSO_SITE_URL ?? '/';
// };

interface withFetchParams {
	url: string;
	headers?: RequestInit['headers'];
	method: RequestInit['method'];
	mode?: RequestInit['mode'];
	credentials?: RequestInit['credentials'];
	dataParams?: string | Record<string, string>
};

interface fetchError {
    Success: false;
    Data: string[];
}

interface fetchSuccess<Type> {
    Success: true;
    Data: Type;
}

interface fetchThen<Type> {
	status: number
	headers: Headers
	response: fetchError | fetchSuccess<Type>
}

interface fetchCatch {
	error: unknown,
	response: fetchError
}

export const SSO_SITE_URL = import.meta.env.VITE_SSO_SITE_URL;
export const SSO_API_URL = import.meta.env.VITE_SSO_API_URL;
export const NO_REDIRECT = parseInt(import.meta.env.VITE_NO_REDIRECT || '0');

export const defaultCustomFetch = <T = unknown>({ 
	url, 
	headers, 
	method, 
	mode, 
	credentials, 
	dataParams 
}: withFetchParams): Promise<fetchThen<T> | fetchCatch> => {

		const body: Record<string, string> = method == 'POST' ? { body: JSON.stringify(dataParams) } : {};

		url = method === 'GET' && typeof dataParams !== 'string' ? `${url}?${new URLSearchParams(dataParams).toString()}` : typeof dataParams === 'string' ? `${url}/${dataParams}` : url;

		return fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				...headers,
			},
			credentials: credentials ?? 'include',
			mode: mode ?? 'cors',
			method,
			...body,
		})
			.then(async (response) => {

				if (response.status === 210) {
					localStorage.setItem('SSO_CREDENTIALS', JSON.stringify(`${response.headers.get('RefreshedToken')}`));
				}

				return {
					status: response.status,
					headers: response.headers,
					response: await response.json(),
				} as fetchThen<T>
			})
	};

export const handleRedirectSSO = NO_REDIRECT
	? () => null
	: () => {
			window.location.href = SSO_SITE_URL;
		};


import { toast } from 'sonner';

interface RetryConfig {
	maxRetries?: number;
	initialDelay?: number;
	maxDelay?: number;
	retryableStatuses?: number[];
	retryableErrors?: string[];
	// Browser-specific options
	checkOnlineStatus?: boolean;
	showUserFeedback?: boolean;
}

interface RetryState {
	attempt: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error?: any;
	lastDelay?: number;
}

const defaultRetryConfig: RetryConfig = {
	maxRetries: 3,
	initialDelay: 1000,
	maxDelay: 10000,
	retryableStatuses: [408, 429, 500, 502, 503, 504],
	retryableErrors: ['Failed to fetch', 'NetworkError', 'Network request failed', 'net::ERR_INTERNET_DISCONNECTED', 'net::ERR_CONNECTION_TIMED_OUT', 'net::ERR_HTTP2_PROTOCOL_ERROR','The Internet connection appears to be offline', 'A network error occurred'],
	checkOnlineStatus: true,
	showUserFeedback: true,
};

export const withRetry = (fetchFn: typeof defaultCustomFetch, retryConfig: RetryConfig = {}) => {
	const config = { ...defaultRetryConfig, ...retryConfig };

	const waitForOnline = async (): Promise<void> => {
		if (!navigator?.onLine) {
			if (config.showUserFeedback) {
				toast.warning('Esperando por conexión de internet...');
			}

			return new Promise((resolve) => {
				const onlineHandler = () => {
					window.removeEventListener('online', onlineHandler);

					if (config.showUserFeedback) {
						toast.info('Conexión reestablecida reintentando...');
					}

					resolve();
				};

				window.addEventListener('online', onlineHandler);
			});
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const shouldRetry = (error: any, state: RetryState): boolean => {
		if (state.attempt >= (config.maxRetries ?? 0)) {
			return false;
		}

		if (error?.status && config.retryableStatuses?.includes(error.status)) {
			return true;
		}

		const errorMessage = String(error).toLowerCase();
		return config.retryableErrors?.some((e) => errorMessage.includes(e.toLowerCase())) ?? false;
	};

	const getDelay = (state: RetryState): number => {
		const initialDelay = config.initialDelay ?? 1000;
		const maxDelay = config.maxDelay ?? 10000;

		const exponentialDelay = initialDelay * Math.pow(2, state.attempt - 1);
		const jitter = Math.random() * 0.25 * exponentialDelay; // 25% jitter
		return Math.min(exponentialDelay + jitter, maxDelay);
	};

	return async <T = unknown>(params: Parameters<typeof defaultCustomFetch<T>>[0]): Promise<ReturnType<typeof defaultCustomFetch<T>>> => {
		const state: RetryState = { attempt: 1 };

		// eslint-disable-next-line no-constant-condition
		while (true) {
			try {
				if (config.checkOnlineStatus && typeof navigator !== 'undefined') {
					await waitForOnline();
				}

				const result = await fetchFn<T>(params);

				if (result.response.Success) {
					if (config.showUserFeedback && state.attempt > 1) {
						toast.success('Conexión restablecida exitosamente!');
					}
					return result;
				}

				return result;
			} catch (error) {
				state.error = error;

				if (shouldRetry(error, state)) {
					state.lastDelay = getDelay(state);

					if (config.showUserFeedback) {
						toast.error(`Petición fallida. Reintentando en ${Math.round(state.lastDelay / 1000)}s... ` + `(Intento ${state.attempt} de ${config.maxRetries})`);
					}

					await new Promise((resolve) => setTimeout(resolve, state.lastDelay));
					state.attempt++;
					continue;
				}

				if (config.showUserFeedback) {
					toast.error('Petición fallida. Intenta más tarde por favor.');
				}

				return {
					error,
					response: {
						Success: false,
						Data: [String(error).split(': ')[1] ?? String(error)],
					}
				} as fetchCatch;
			}
		}
	};
};

export const customFetch = withRetry(defaultCustomFetch);
// export const validarOTP = customFetch<string>({ url: `${SSO_API_URL}/Valida/OTP`, method: 'POST' });
export const validarOTP = (params: Record<string, string>) => 
	customFetch<string>({ 
	  url: `${SSO_API_URL}/Valida/OTP`, 
	  method: 'POST',
	  dataParams: params 
	});
//export const validarSesion = customFetch({ url: `${SSO_API_URL}/Valida/Sesion`, method: 'POST' });