import { useMemo, useEffect } from 'react';
import { MainContainer, BackgroundImg, IconContainer, Grid, LogoutIcon, AppBar, IconButton } from './styled';
import { useLogout, useDialog } from 'hooks';
import { ExitDialog, SideBar } from 'components';
import { createTheme, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import { getGlobalTheme } from 'styles/globalTheme';
import { ThemeProvider as ThemeProviderStyledComponents } from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import themeStyledComponents from 'styles/theme/index';
import useStore from '@/store/main/action';
import { SIDEBAR_ITEMS } from './constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIsTouchDevice } from '@/utils';

const GeneralLayout = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
    const navigate = useNavigate()

    const { primaryColor, resetReducer, backgroundImg, userInfo, back, storeState, icon, cDescripcion } = useStore();

    useEffect(() => {
        resetReducer();
    }, []);

    const theme = useMemo(() => createTheme(getGlobalTheme(primaryColor)), [primaryColor]);

    const { open, handleOpenDialog, handleCancelar } = useDialog();

    const { handleLogout, encuestaId } = useLogout(resetReducer, storeState);
    // 5% 10px 20px 10px
    const location = useLocation();

    const isEncuestaToken = location.pathname.startsWith('/encuestaToken');
    const show = userInfo?.nUsuario || isEncuestaToken;

    // const isAdmin = location.pathname.includes('/admin') && userInfo?.nTipoUsuario === 1;
    const isAdmin = false;

    const xs = useMediaQuery('(min-height: 600px)');
    const xsWidth = useMediaQuery('(min-width: 540px)');

    const isTouchDevice = xsWidth && getIsTouchDevice();
    return (
        <div>
            <title>{isAdmin ? 'Encuestas Administrador' : 'Encuestas'}</title>
            <ThemeProvider theme={theme}>
                <ThemeProviderStyledComponents theme={themeStyledComponents}>
                    {show && backgroundImg && <BackgroundImg key="bgimg" url={backgroundImg} container />}
                    <MainContainer
                        padding={show ? null : '15px 0 0 0'}
                        bggrey={(!!show && !isAdmin).toString()}
                        admin={isAdmin.toString()}
                        container
                        direction="column"
                    >
                        {show && (
                            <AppBar
                                admin={isAdmin.toString()}
                                alignItems="center"
                                justifyContent="space-between"
                                item
                                container
                            >
                                <Grid item container={!!back} xs={back ? 3 : undefined}>
                                    {back && !isEncuestaToken ? (
                                        <IconButton disableRipple onClick={() => navigate(back)}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    ) : null}
                                    {icon && (
                                        <IconContainer
                                            key="bgicon"
                                            component="img"
                                            image={icon}
                                            maxheight={
                                                encuestaId && primaryColor ? xs ? '125px' : '100px'
                                                    : isAdmin ? '70px' : '100px'
                                            }
                                            maxwidth={
                                                encuestaId && primaryColor
                                                    ? xs ? '125px' : '100px'
                                                    : isAdmin ? '157.5px' : '225px'
                                            }
                                        />
                                    )}
                                </Grid>
                                {
                                    (cDescripcion) && (
                                        <Grid xs={true} item>
                                            <Typography textAlign={'end'} variant={isTouchDevice ? 'h6' : 'subtitle2'}><strong>{cDescripcion}</strong></Typography>
                                        </Grid>
                                    )
                                }
                                <Grid item padding={xs && !isAdmin ? '30px 20px 10px 0px' : '10px 20px 10px 0'}>
                                    {(show && isEncuestaToken) || encuestaId ? null : (
                                        <IconButton onClick={handleOpenDialog}>
                                            <LogoutIcon admin={isAdmin.toString()} />
                                        </IconButton>
                                    )}
                                </Grid>
                            </AppBar>
                        )}
                        <Grid container item xs>
                            <Grid item>
                                {isAdmin && !back ? (
                                    <SideBar
                                        primarycolor={primaryColor}
                                        Items={SIDEBAR_ITEMS}
                                    />
                                ) : null}
                            </Grid>
                            <Grid item xs container direction="column">
                                {children}
                            </Grid>
                        </Grid>
                        <ExitDialog
                            title="¿Está seguro que desea cerrar sesión?"
                            subtitle=""
                            open={open}
                            handleCancelar={handleCancelar}
                            handleAceptar={handleLogout}
                        />
                    </MainContainer>
                </ThemeProviderStyledComponents>
            </ThemeProvider>
        </div>
    );
};

export default GeneralLayout;
