import React from 'react';
import { Wrapper, CarouselContainer, CarouselSlot } from './styled';
import { StateProps } from '@/hooks/useWizard';
import { Fragment } from 'react';
// https://codesandbox.io/s/lrk6955l79?file=/src/index.js:1084-1367
const getOrder = ({ index, pos, numItems }: Record<string, number>) => {
    return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

interface props {
    numItems: number;
    children: React.ReactNode;
    state: StateProps;
}

const Carousel = ({ numItems, state, children }: props) => {
    return (
        <Wrapper>
            <CarouselContainer dir={state.dir} sliding={state.sliding}>
                {React.Children.map(children, (child, index) => {
                    const order = getOrder({ index, pos: state.pos, numItems });

                    return order === 0 ? (
                        <CarouselSlot
                            key={index}
                            order={order}
                        >
                            {child}
                        </CarouselSlot>
                    ) : <Fragment key={index} />;
                })}
            </CarouselContainer>
        </Wrapper>
    );
};

export default Carousel;
