import { useState, useEffect } from 'react';
import { API_URL } from '@/constants';
import { useNavigate } from 'react-router-dom';
import useStore from '@/store/main/action';
import { UserInfo } from '@/store/main/reducer';
import { simpleFetch } from '@/utils/simpleFetch';

export interface Encuesta {
    nEncuesta: number
    cDescripcion: string
    cToken: string,
}

const useMain = () => {
    const [status, setstatus] = useState(0);
    const [loading, setloading] = useState(false);
    const [encuestas, setencuestas] = useState<Encuesta[]>();
    const [encuestaInfo, setEncuestaInfo] = useState<Encuesta>()
	
    const navigate = useNavigate();

    const { 
        bIsLogged, 
        userInfo,
	} = useStore();

    const handleGetEncuestas = async (userInfo: UserInfo) => {
        
        if (userInfo?.nUsuario) {
            // try {
            //     const datos = {
            //         nUsuario: userInfo.nUsuario,
            //     };
            //     fetch(`${API_URL}Obten/EncuestasUsuario`, {
            //         //${urlLocal}Encuestas/ObtenerEncuestasUsuario`
            //         body: JSON.stringify(datos),
            //         method: 'POST',
            //         headers: {
            //             'Content-type': 'application/json',
            //             Authorization: `BEARER ${cSessionToken}`,
            //         },
            //     })
            //         .then((res) => res.json())
            //         .catch((error) => {
            //             console.log(error, 'error');
            //             setstatus(3);
            //         })
            //         .then((response) => {
            //             if (response) {
            //                 if (response.Success === true && response.Data) {
            //                     setstatus(2);
            //                     setencuestas(response.Data);
            //                 } else {
            //                     setstatus(3);
            //                 }
            //             } else {
            //                 setstatus(3);
            //             }
            //             setloading(false);
            //         });
            // } catch (e) {
            //     setstatus(4);
            // }

            const response = await simpleFetch<Encuesta[]>({
                url: `${API_URL}Obten/EncuestasUsuario`,
                method: 'POST',
                params: {
                    nUsuario: userInfo.nUsuario
                },
                headers: { Authorization: `Bearer ${localStorage.getItem('SSO_CREDENTIALS') ?? ''}`}
            })

            if(!response.success){
                setstatus(3)
            }else if( response.success && response.data){
                setstatus(2);
                setencuestas(response.data);
            }else {
                setstatus(3);
            }
            setloading(false);
        }
    };

    const handleClickCard = (item: Encuesta) => {
        setEncuestaInfo(item)
        navigate(`encuestas/${item.cToken}`);
        // setAssets()
    };

    useEffect(() => {
        bIsLogged === true && handleGetEncuestas(userInfo);
    }, [bIsLogged]);

    const [page, setPage] = useState(1);
    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const notFound = status === 3;

    return {
        handleClickCard,
        encuestas,
        page,
        handleChange,
        status,
        loading,
        notFound,
        encuestaInfo
    };
};

export default useMain;
