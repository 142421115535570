import styled from "styled-components";

interface ItemProps {
  img: string
}

export const Item = styled.div<ItemProps>`
  text-align: center;
  padding: 100px;
  background-image: ${props => `url(${props.img})`};
  background-size: cover;
`;

interface CarouselContainerProps {
  sliding: boolean
  dir: string
}

export const CarouselContainer = styled.div<CarouselContainerProps>`
  display: flex;
  animation: ${props => (props.sliding ? "none" : "fadeIn 2s")};
`;

  /*// transform: ${props => {
  //   if (!props.sliding) return "translateX(calc(0%))";
  //   if (props.dir === PREV) return "translateX(calc(-100%))";
  //   if (props.dir === NEXT) return "translateX(calc(100%))";
  // }};*/

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

interface CarouselSlotProps {
  order: number
}

export const CarouselSlot = styled.div<CarouselSlotProps>`
  flex: 1 0 100%;
  flex-basis: 100%;
  min-height: 250px;
  order: ${props => props.order};
`;


