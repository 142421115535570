import { MetaInfo, Select as SelectType } from "@/hooks/useSurvey"
import { Box, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"

interface props {
    select: MetaInfo
    validaPorToken: boolean | undefined
    index: number
    handleMetaInfo: (i: number, value: string) => void
    metaInfoDepende?: number | null;
    handleObtenerSelect: (nEncuesta: number, nEncuestaMetaInfo: number, nRespuestaMetaInfoDepende: number | null) => Promise<SelectType[] | undefined>
}

export const Selects = ({ select, index, handleMetaInfo, metaInfoDepende = null, handleObtenerSelect }: props) => {
    const [values, setValues] = useState<SelectType[]>();

    const handleGetInformation = async () => {
        const response = await handleObtenerSelect(4, select.nEncuestaMetaInfo, metaInfoDepende);
        if (response) {
            setValues(response)
        }
    }

    const handleChangeSelect = (event: SelectChangeEvent) => {

        const value = event.target.value as string

        handleMetaInfo(index, value.toString());
    };

    useEffect(() => {
        handleGetInformation()
    }, [])

    useEffect(() => {
        if (metaInfoDepende) {
            setValues(undefined)
            handleMetaInfo(index, '');
            handleGetInformation()
        }
    }, [metaInfoDepende])

    return (

        <Grid m={2}>
            {
                values
                    ?
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id={select.cNombreMetaInfo}>{select.cNombreMetaInfo}</InputLabel>
                        <Select
                            labelId={select.cNombreMetaInfo}
                            id={select.cNombreMetaInfo}
                            value={select.cValor ?? ''}
                            label={select.cNombreMetaInfo}
                            onChange={handleChangeSelect}
                            disabled={(select.nEncuestaMetaInfoDepende && !metaInfoDepende) ? true : false}
                        >
                            {
                                values?.map((select, index) => {
                                    return <MenuItem key={`${select.cRespuesta} - ${index}`} value={select.nRespuesta}>{select.cRespuesta}</MenuItem>
                                })

                            }

                        </Select>
                    </FormControl>
                    : <Box>
                        <Box color={'gray'}>{select.cNombreMetaInfo}</Box>
                        <LinearProgress color="success" />
                    </Box> 
                    
            }
        </Grid>
    )
}
